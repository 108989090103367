<template>
    <section>
        <v-card id="top" class="custom-border border pa-2">
            <v-card-text>
                <section class="d-flex mx-1 mt-3" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'">
                    <div class="poppins fw600 f18 secondary-1--text mr-auto my-1">My Courses</div>
                    <div class="d-flex justify-end align-center ml-auto my-1">
                        <section style="width:150px">
                            <FormSelectObject
                                :placeholder="'Category'"
                                :items="categories"
                                :value.sync="filter1.my_course"
                                :loading="loading2"
                                class="mx-1"
                            />
                        </section>
                        <section style="width:100px">
                            <FormSelectArray
                                :placeholder="'Sort'"
                                :items="SORT_BY"
                                :value.sync="filter1.course_name_sort"
                            />
                        </section>

                        <v-btn 
                            text 
                            dense
                            @click="gallery =  true"
                            class="hidden-sm-and-down mx-1"
                        >
                            <v-icon size="22" :class="gallery ? 'primary--text' : 'secondary-2--text'">
                            mdi-view-gallery-outline
                            </v-icon>
                        </v-btn>
                        <!-- <v-btn 
                            text 
                            dense
                            @click="gallery = false"
                            class="hidden-sm-and-down"
                        >
                            <v-icon size="22" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
                            mdi-format-list-bulleted
                            </v-icon>
                        </v-btn> -->
                    </div>
                </section>
                
                <v-progress-linear
                    indeterminate
                    color="primary"
                    rounded
                    height="3px"
                    class="mt-3"
                    v-if="loading"
                ></v-progress-linear>
                
                <!-- <v-sheet :height="!gallery ? 'auto' : 'auto'" :max-height="gallery ? '80vh' : '60vh'" v-if="courses.length>0" class="d-flex overflow-y-auto my-4" :class="[gallery ? 'flex-wrap' : 'flex-column']">
                    <CardGallery 
                        v-for="(item,i) in courses"
                        v-if="gallery"
                        :key="i"
                        :data="item"
                        :progress="getProgress(item)"
                        class="ma-1"
                    />
                    <CardList 
                        v-for="(item,i) in courses"
                        v-if="!gallery"
                        :key="i"
                        :data="item"
                        :progress="getProgress(item)"
                        class="my-1"
                    />
                </v-sheet> -->
                <!-- <div class="poppins fw600 f13 secondary-4--text font-italic"> No courses available. </div> -->
                <section v-if="gallery"  class="d-flex flex-wrap">
                    <CardGallery 
                        v-for="(item,i) in courses"
                        :key="item.id"
                        :data="item"
                        :progress="getProgress(item)"
                        class="ma-1"
                    />
                </section>
                <!-- <section v-if="!gallery" class="d-flex flex-column justify-center'">
                    <CardList 
                        v-for="(item,i) in courses"
                        :key="item.id"
                        :data="item"
                        :progress="getProgress(item)"
                        class="my-1"
                    />
                </section> -->
                <v-sheet
                    v-if="!loading && courses.length === 0"
                    color="transparent"
                    class="d-flex flex-column align-center justify-center pb-10"
                    height="400"
                >
                    <div class="">
                    <v-img 
                        :max-width="$vuetify.breakpoint.mobile?200:300" 
                        :src="require('@/assets/default/empty_drafts.png')"/>
                    </div>
                    <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Courses</h3>
                </v-sheet>
            </v-card-text>
            <!-- <FormPagination 
                :pageCount="pageCount" 
                :page="page"
                :paginate="paginate"
                @page="(e) => { page = e, getCourses() }" 
                @paginate="(e) => { paginate = e, getCourses() }"/> -->
        </v-card>
        <SuccessDialog :dialog="successDialog" :title="'Transaction is on progress'" :message="'Check on your transaction page for updates / action needed'" :icon="true" />
    </section>
    
    
</template>

<style scoped>
</style>

<script>
import { mapActions, mapState, mapMutations } from "vuex"
import GalleryCard from "@/components/user/course/GalleryCard.vue"
import ListCards from '@/components/user/course/ListCard.vue'
import NominationDialog from "@/components/user/course/tocart/NominationDialog.vue"
import CardGallery from '@/components/user/course/CardGallery.vue';
import CardList from '@/components/user/course/CardList.vue';
import SuccessDialog from '@/constants/reusable/SuccessDialog.vue';

export default {
    components: {
        GalleryCard,
        NominationDialog,
        ListCards,
        CardGallery,
        CardList,
        SuccessDialog
    },
    data: () => ({
        dialog: false,
        successDialog: false,
        loading: false,
        loading1: false,
        loading2: false,
        selected: null,
        removeLoading: false,
        checkoutLoading: {},
        errorPrompt: false,
        nomination: null,
        nominationLoading: false,
        filter1: {
            my_course: '',
            course_name_sort: ''
        },
        filter2: {
            classes: [],
            course_name_sort: '',
            price_min: '',
            price_max: ''
        },
        gallery: true,
        categories: [
            { text: 'All Categories', value: ''},
            { text: 'Junior Level', value: 'Junior'},
            { text: 'Intermediate Level', value: 'Intermediate'},
            { text: 'Senior Level', value: 'Senior'},
        ],
        SORT_BY: [
            { text: 'A to Z', value: 'ASC' },
            { text: 'Z to A', value: 'DESC' },
        ],
        pageCount: 1,
        paginate: '10',
        page: 1,
    }),
    mounted() {
        //this.loading = true

        window.addEventListener('resize', this.onResize, {passive: true})
        this.onResize()

        this.getCourses()
        
        this.getClassCategory() 
    }, 
    computed: {
        ...mapState({
            course: (state) => state.course,
            other_courses: (state) => state.other_courses,
            // courses(state) {
            //     return [...state.free_courses, ...state.paid_courses].filter(item => item.uuid !== this.$route.params.uuid)
            // }
        }),
        ...mapState('usr', {
            cart: (state) => state.cart,
            user_courses: (state) => state.courses,
            courses: (state) => state.courses,
            classes: (state) => state.classes
        }),

        getPrice() {
            if(this.cart.length === 0) {
                return 0
            } else {
                let price = 0
                this.cart.forEach(i => {
                    if(!i.is_free) {
                        price += parseInt(i.price ? i.price : 0)
                    }
                })

                return price
            }
        }
    },
    methods: {
        ...mapActions(['otherCoursesAction']),
        ...mapMutations(['alertMutation']),
        ...mapMutations('usr',['cartMutation']),
        ...mapActions('usr', ['getClassCategoryAction', 'removeFromCart', 'getCoursesAction', 'getCartItems','showCourseAction']),

        getCourses() {
        //     this.loading = true
        //     this.getCoursesAction({...this.filter1, page: this.page, paginate: Number(this.paginate)}).then((res) => {
        //         this.pageCount = res.last_page
        //         this.paginate = String(res.per_page)
        //         this.page = res.current_page
        //     }).finally(() => {
        //         this.loading = false
        //     })
            this.loading = true
            this.getCoursesAction(this.filter1).finally(() => {
                this.loading = false
            })
        },

        getCart() {
            this.loading1 = true
            this.getCartItems().finally(() => {
                this.loading1 = false
            })
        },
        
        // onResize(){
        //     if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        //         this.gallery = false
        //     }
        // },

        getProgress(item) {
            // If the course is marked as completed, return 100
            if (item.completed_course) {
                return 100;
            }

            // Calculate the sum of done assessments, topics, and sub-topics
            const totalDone = item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics;

            // Calculate the total count of assessments, topics, and sub-topics
            const totalCount = item.assessments_count + item.topics_count + item.sub_topics_count;

            // Avoid division by zero
            if (totalCount === 0) {
                return 0;
            }

            // Calculate progress as a percentage and round it
            let progress = Math.round((totalDone / totalCount) * 100);

            // Ensure progress doesn't exceed 100%
            if (progress > 100) {
                progress = 100;
            }

            // Return the calculated progress, defaulting to 0 if NaN or invalid
            return isNaN(progress) || progress < 0 ? 0 : progress;
        },


        // getClassCategory() {
        //     this.loading2 = true
        //     this.getCoursesAction().then(res => {
        //         res.forEach((item) => {
        //         this.categories.push({
        //             text: item.title,
        //             value: item.id.toString(),
        //         })
        //         })
        //     }).finally(() => {
        //         this.loading2 = false
        //     })
        // },

        forRegistration(item){
            if(item.available_until) {
                let today = Date.now()
                if(item.available_until.end_date) {
                    if(today > new Date(item.available_until.end_date)) {
                        return false
                    } else {
                        return true
                    }
                } else return true
            } else return false
        },

        truncateDescription(desc, length) {
            return desc.length > length ? `${desc.slice(0, length-4)}...` : desc
        },

        removeFromList(uuid, id){
            let filtered = this.cart.filter(i => i.uuid !== uuid)
            //this.cartMutation(filtered)

            if(filtered.length === 0) {
                localStorage.removeItem('cart')
            } else {
                localStorage.setItem('cart', JSON.stringify(filtered))
            }
            this.removeFromCart({course_id: id})
        },

        save(file) {
            this.nomination = file
            this.dialog = false
            this.proceedToPayment()
        },

        proceedToPayment(){
            let _index = this.cart.findIndex(_course => _course.id === this.selected.id)
            this.checkoutLoading[_index] = true
            let _form = new FormData()

            _form.append('course_id', this.selected.id)

            if(this.nomination) {
                _form.append('nomination_form', this.nomination)
                this.nominationLoading = true
            }

            const config = { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            }

            this.$api.post(`/user/checkout`, _form, config).then(res => {
                if (res.data.hasOwnProperty('mode_of_payment')) {
                    this.checkoutLoading[_index] = false
                    this.alertMutation({
                        show: true,
                        text: 'Enrollment Success!',
                        type: "success"
                    })
                    this.getCourses()
                    this.getCart()
                } else {
                    if(this.nomination) {
                        this.$router.push({ name: 'User Transaction History' })
                    } else {
                        var newDoc = document.implementation.createHTMLDocument();
                        var parsedContent = document.createElement('div');

                        var parser = new DOMParser();
                        var xmlDoc = parser.parseFromString(res.data, 'text/html');

                        parsedContent.appendChild(xmlDoc.documentElement);
                        newDoc.body.appendChild(parsedContent);
                        
                        var newTab = window.open();
                        newTab.document.write(newDoc.documentElement.outerHTML);

                        this.checkoutLoading[_index] = false
                        location.href = '/dashboard'
                    }
                    
                }

                this.nominationLoading = false
            }).catch(e => {
                this.nominationLoading = false
                this.dialog = false
                if(e.response.status === 404) {
                    this.checkoutLoading = {
                        ...this.checkoutLoading,
                        _index: false
                    }
                    this.alertMutation({
                        show: true,
                        text: 'Checkout is not available at this moment',
                        type: "error"
                    })
                } else if(e.response.status === 409) { 
                    this.checkoutLoading[_index] = false
                    this.alertMutation({
                        show: true,
                        text: e.response.data.message,
                        type: "error"
                    })
                } else {
                    this.checkoutLoading[_index] = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                }

                this.checkoutLoading = {
                    ...this.checkoutLoading,
                    _index: false
                }
            }).finally(() => {
                this.loading = false
            })
            
        },
        // filterOtherCourses() {
        //     this.loading = true
        //     this.getCoursesAction({search: this.search, page: this.page, paginate: Number(this.paginate)}).then((res) => {
        //         console.log(res,'test')
        //         this.pageCount = res.last_page
        //         this.paginate = String(res.per_page)
        //         this.page = res.current_page
        //     }).finally(() => {
        //         this.loading = false
        //     })
        // },
    },
    watch: {
        filter1: {
            handler(val) {
                this.getCourses()
                
            },
            deep: true
        },

        successDialog(val) {
            if(val) {
                setTimeout(() => {
                    this.successDialog = false 
                }, 4000)
            }
        }
    }
}
</script>